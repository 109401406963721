<template>
  <div>
    <q-form ref="editForm">
      <c-card class="cardClassDetailForm" title="상세">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <c-text
              :required="true"
              :editable="editable"
              label="공사명"
              name="constructionName"
              v-model="construction.constructionName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-datepicker
              :required="true"
              :editable="editable"
              :range="true"
              label="공사기간"
              name="period"
              v-model="construction.period"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-vendor
              :editable="editable"
              label="주 공사업체"
              name="vendorCd"
              v-model="construction.vendorCd">
            </c-vendor>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-checkbox
              :editable="editable"
              :isFlag="true"
              label="공사종료여부"
              name="constructionFinishFlag"
              v-model="construction.constructionFinishFlag"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-text
              :editable="editable"
              type="number"
              label="안전보건관리비"
              name="safetyHealthManageCost"
              v-model="construction.safetyHealthManageCost">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-text
              :editable="editable"
              label="코스트센터"
              name="costCenter"
              v-model="construction.costCenter">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-text
              :editable="editable"
              label="현장 총괄"
              name="onSiteManager"
              v-model="construction.onSiteManager">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-text
              :editable="editable"
              label="안전책임자"
              name="safetyManager"
              v-model="construction.safetyManager">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <c-moc 
              :editable="editable"
              :document.sync="construction"
              :documentId="construction.sopConstructionId"
              documentTitle="constructionName"
              name="sopMocId"
              v-model="construction.sopMocId"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-textarea
              :editable="editable"
              :rows="2"
              label="공사내역"
              name="constructionDetails"
              v-model="construction.constructionDetails">
            </c-textarea>
          </div>
        </template>
      </c-card>
    </q-form>
    <c-table
      ref="vendortable"
      title="공사 참여업체 목록"
      :columns="gridVendor.columns"
      :data="construction.vendors"
      :gridHeight="gridVendor.height"
      :editable="editable"
      :hideBottom="true"
      :isExcelDown="false"
      :filtering="false"
      :isFullScreen="false"
      :columnSetting="false"
      selection="multiple"
      rowKey="vendorCd"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            label="제외" icon="remove" 
            @btnClicked="deleteVendor" />
          <c-btn 
            v-if="editable" 
            label="추가" icon="add" 
            @btnClicked="addVendor" />
        </q-btn-group>
      </template>
    </c-table>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn v-if="editable&&isOld" label="삭제" icon="delete" @btnClicked="deleteContruction" />
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="construction"
            :mappingType="mappingType"
            label="저장" 
            icon="save"
            @beforeAction="saveConstruction"
            @btnCallback="saveConstructionCallback" />
        </q-btn-group>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'constructionInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopConstructionId: '',
      }),
    },
    construction: {
      type: Object,
      default: () => ({
        sopConstructionId: '',  // 공사 일련번호
        constructionName: '',  // 공사명
        constructionDetails: '',  // 공사내역
        constructionStartDate: '',  // 공사 시작일
        constructionEndDate: '',  // 공사 종료일
        period: [],
        constructionFinishFlag: 'N',  // 공사 종료 여부
        safetyHealthManageCost: 0,  // 안전보건관리비(총 액)
        costCenter: '',  // 코스트센터
        onSiteManager: '',  // 현장 총괄
        safetyManager: '',  // 안전책임자
        vendorCd: '',  // 주 공사업체
        sopMocId: '',  // MOC 일련번호
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        vendors: [], // 참영업체
        deleteVendors: [], // 참영업체 [삭제]
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      gridVendor: {
        columns: [
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '참여업체',
            align: 'center',
            style: 'width:120px',
            sortable: false,
            fix: true,
          },
          {
            name: 'constructionDetails',
            field: 'constructionDetails',
            label: '공사내역',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'textarea',
          },
          {
            name: 'period',
            field: 'period',
            label: '참여기간',
            align: 'center',
            style: 'width:150px',
            sortable: false,
            type: 'date',
            range: true,
          },
          {
            name: 'attach1',
            field: 'attach1',
            label: '안전관리계획서',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_SAFETY_MANAGE_PLAN',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach2',
            field: 'attach2',
            label: '공사(작업)위험성평가',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_RISK_ASSESS',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach3',
            field: 'attach3',
            label: '작업계획서',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_WORK_PLAN',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach4',
            field: 'attach4',
            label: '교육결과',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_EDU_CERTI',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach5',
            field: 'attach5',
            label: '산업안전보건관리비 계획',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_MANAGE_COST_PLAN',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach6',
            field: 'attach6',
            label: '보험 증명원',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_INSURANCE_CERTI',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach7',
            field: 'attach7',
            label: '검진결과',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_HEA_CHECK',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach8',
            field: 'attach8',
            label: '안전검사증',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_SAFETY_INSP_CERTI',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach9',
            field: 'attach9',
            label: '설비제원표',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_EQUIP_SPEC_TABLE',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach10',
            field: 'attach10',
            label: '자격증명서<br/>(지게차, 크레인 등)',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_CREDENTIALS',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach11',
            field: 'attach11',
            label: '기타',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_ETC',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
        ],
        height: '500px',
      },
      editable: true,
      mappingType: 'PUT',
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return String(this.contentHeight - 400) + 'px';
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sop.scm.construction.insert.url;
      this.deleteUrl = transactionConfig.sop.scm.construction.delete.url;
      // code setting
      // list setting
    },
    research() {
      this.$emit('getDetail')
    },
    addVendor() {
      this.popupOptions.title = '협력업체 검색'; // 협력업체 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeVendorPopup;
    },
    closeVendorPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.construction.vendors, { vendorCd: item.vendorCd }) === -1) {
            this.construction.vendors.push({
              sopConstructionRelationVendorId: '',  // 공사 참여업체 일련번호
              sopConstructionId: this.popupParam.sopConstructionId,  // 공사 일련번호
              vendorCd: item.vendorCd,  // 참여업체 코드
              vendorName: item.vendorName,  // 참여업체 명
              constructionDetails: '',  // 공사내역
              participationStartDate: '',  // 참여 시작일
              participationEndDate: '',  // 참여 종료일
              period: [],
              regUserId: this.$store.getters.user.userId, // 등록자 ID
              editFlag: 'C',
            })
          }
        })
      }
    },
    deleteVendor() {
      let selectData = this.$refs['vendortable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '제외할 행을 지정하세요.', // 제외할 행을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '제외하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(selectData, item => {
              if (!this.construction.deleteVendors) {
                this.construction.deleteVendors = [];
              }
              if (item.editFlag !== 'C' && this.$_.findIndex(this.construction.deleteVendors, { vendorCd: item.vendorCd }) === -1) {
                this.construction.deleteVendors.push(item)
              }
              this.construction.vendors = this.$_.reject(this.construction.vendors, item);
            });
            this.$refs['vendortable'].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    deleteContruction() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopConstructionId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveConstruction() {
      if (this.isOld) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.sop.scm.construction.update.url;
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.sop.scm.construction.insert.url;
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.construction.regUserId = this.$store.getters.user.userId
              this.construction.chgUserId = this.$store.getters.user.userId
              
              this.construction.constructionStartDate = this.construction.period[0]
              this.construction.constructionEndDate = this.construction.period[1]
              
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveConstructionCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'sopConstructionId', _result.data)
      this.$emit('getDetail');
    },
  }
};
</script>